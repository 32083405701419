<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :footer-actions="footerActions"
      :sums="sums"
      :is-page-show="true"
      api-url="/goods-production-requirement-list"
      api-download-url="/goods-production-requirement-export"
      download-file-name="sx-may-dinh-luong.xlsx"
      @showDialogCreate="showDialogCreate"
      @showDialogDetail="showDialogDetail"
      @joinRequirement="joinRequirement"
      @showDialogQuantitative="showDialogQuantitative"
    ></custom-table>

    <v-dialog v-model="createDialog" max-width="1024px">
      <RequirementCreate
        v-if="createDialog"
        @refreshData="refreshCustomTable"
        @close="hideDialogCreate"
      />
    </v-dialog>

    <v-dialog v-model="detailDialog" max-width="1366px">
      <RequirementDetail
        v-if="detailDialog"
        :item="updatingItem"
        @refreshData="refreshCustomTable"
        @close="hideDialogDetail"
      />
    </v-dialog>

    <v-dialog v-model="quantitativeDialog" max-width="1366px">
      <RequirementQuantitative
        v-if="quantitativeDialog"
        :item="updatingItem"
        @refreshData="refreshCustomTable"
        @close="hideDialogQuantitative"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  IMAGE_OPTIONS,
  GOODS_PRODUCTION_REQUIREMENT_STATUS,
} from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "Requirement",
  components: {
    RequirementCreate: () =>
      import("@/components/goods_production/RequirementCreate"),
    RequirementDetail: () =>
      import("@/components/goods_production/RequirementDetail"),
    RequirementQuantitative: () =>
      import("@/components/goods_production/RequirementQuantitative"),
  },
  data: () => ({
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    imageOptions: [...IMAGE_OPTIONS],
    statusOptions: [...GOODS_PRODUCTION_REQUIREMENT_STATUS],
    createDialog: false,
    detailDialog: false,
    quantitativeDialog: false,
    updatingItem: {},
  }),
  computed: {},
  created() {
    const columns = [
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: false,
        sortName: "tracking_id",
        key: "tracking_id",
        required: true,
        action: "showDialogDetail",
        actionPermissions: [],
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.created_at"),
        placeholder: this.$t("labels.created_at"),
        name: "created_at",
        hasSort: false,
        sortName: "created_at",
        key: "created_at",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.confirmed_at"),
        placeholder: this.$t("labels.confirmed_at"),
        name: "confirmed_at",
        hasSort: false,
        sortName: "confirmed_at",
        key: "confirmed_at",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.updated_at"),
        placeholder: this.$t("labels.updated_at"),
        name: "updated_at",
        hasSort: false,
        sortName: "updated_at",
        key: "updated_at",
        required: true,
      },
      {
        type: "select-employee",
        label: this.$t("labels.employee_create"),
        placeholder: this.$t("labels.employee_create"),
        name: "id_identity",
        hasSort: false,
        sortName: "identity_name",
        key: "identity_name",
        required: true,
      },
      {
        type: "select-employee",
        label: this.$t("labels.identity_confirmed"),
        placeholder: this.$t("labels.identity_confirmed"),
        name: "id_identity_confirm",
        hasSort: false,
        sortName: "identity_confirm_name",
        key: "identity_confirm_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.sku"),
        placeholder: this.$t("labels.sku"),
        name: "total_sku",
        hasSort: false,
        sortName: "total_sku",
        key: "total_sku",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.quantity"),
        placeholder: this.$t("labels.quantity"),
        name: "total_item",
        hasSort: false,
        sortName: "total_item",
        key: "total_item",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.note"),
        placeholder: this.$t("labels.note"),
        name: "note",
        hasSort: false,
        sortName: "note",
        key: "note",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status"),
        placeholder: this.$t("labels.status"),
        name: "status",
        hasSort: false,
        sortName: "status",
        key: "status",
        options: this.statusOptions,
        required: true,
      },
      {
        type: "button",
        label: this.$t("labels.note"),
        headerAction: "showDialogCreate",
        headerActionColor: "primary",
        headerActionLabel: this.$t("labels.add"),
        action: false,
        /* actionColor: 'success',
                actionLabel: this.$t('labels.join'), */
        required: true,
        buttonActions: [
          {
            action: "joinRequirement",
            text: this.$t("labels.join"),
            color: "success",
            permissions: [],
            allowKey: "can_join",
          },
        ],
      },
    ];
    this.columns = [...columns];

    const footerActions = [];
    this.footerActions = [...footerActions];

    const sums = [];
    this.sums = [...sums];
  },
  methods: {
    refreshCustomTable() {
      this.$refs.customTable.getList();
    },
    showDialogCreate() {
      this.createDialog = true;
    },
    hideDialogCreate() {
      this.createDialog = false;
    },
    showDialogDetail(item) {
      this.updatingItem = { ...item };
      this.detailDialog = true;
    },
    hideDialogDetail() {
      this.updatingItem = {};
      this.detailDialog = false;
    },
    showDialogQuantitative(item) {
      this.updatingItem = { ...item };
      this.quantitativeDialog = true;
    },
    hideDialogQuantitative() {
      this.updatingItem = {};
      this.quantitativeDialog = false;
    },
    async joinRequirement(item) {
      if (
        !confirm(
          this.$t(`messages.goods_production_requirement_join`, {
            tracking: item.tracking_id,
          })
        )
      ) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/goods-production-requirement-join", {
          id_goods_production_requirement: item.id,
        });
        this.isLoading = false;
        const msg = this.$t("messages.update_success");
        this.$vToastify.success(msg);
        this.refreshCustomTable();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
